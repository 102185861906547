.media-component {
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  gap: 1rem;
}

.media-component img {
  margin: 1rem;
  width: 7rem;
  height: 7rem;
}

.media-component .media-name {
  font-size: 1rem;
  color: rgb(17, 0, 255);
}

.media-component .no-photos{
  font-size: 2rem;
  font-weight: 600;
  color:firebrick;
}