

.tableAddRecordBtn {
  position: fixed;
  bottom: 40px;
  right: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: aquamarine;
  font-size: 3rem;
}