
/* CSS for AUTHFORMHEADER */

.afh {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: var(--form-header-padding);
  width: 100%;
  font-size: 1rem;
}

.afh .afh-title {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.afh .afh-title .afh-title-name {}

.afh .afh-title .afh-title-img {
  width: 6rem;
  border-radius: 50%;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.afh .afh-close-btn {
  position: absolute;
  top: -1rem;
  right: -1.5rem;
}

.afh .afh-close-btn:hover {
}

.afh .afh-close-btn button {
  font-size: 3rem;
  color: #fff;
  background-color: rgb(88, 202, 255);
  padding: 0 0.9rem;
  border-radius: 0 20px 0 20px;
  border: none;cursor: pointer;
}


.afh .afh-close-btn .btn-div {}