.media-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
}

.hide-media-view {
  display: none;
}

.show-media-view {
  display: block;
  padding: 1rem;
}

.media-view .header .title-name {
  font-size: 1.5rem;
  font-weight: 600;
}

.media-view .header button {
  border-radius: 0.6rem;
  border: 0.1rem solid;
  padding: 0.5rem;
  margin: 0.2rem;
  font-size: 1.2rem;
}

.media-view .header button:hover {
  outline: 0.1rem solid rgb(121, 255, 121);
  cursor: pointer;
  background-color: #d5ffd5b5;
}

.media-view .header .loader {}

.media-view .media-view-img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.media-view .media-view-img img {
  width: 100%;
  height: auto;
}

.media-view .media-view-metadata {
  position: absolute;
  top: 5%;
  background-color: aliceblue;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 1rem;
}

.media-view .media-view-metadata p {
  padding: 0.2rem;
  outline: 0.1rem solid;
  margin: 0.2rem;
}