.ast-media-view {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}

.show-media {
  display: block;
}

.hide-media {
  display: none;
}



.ast-media-view .ast-media-view-body {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: antiquewhite;
  position: relative;
}


.ast-media-view .ast-media-view-body .media {
  grid-column: 1 / 12;
  margin: auto;
  outline: 0.1rem solid;
}

.ast-media-view .ast-media-view-body .media-container {

  margin: auto;
  display: flex;
  justify-content: center;
}

.ast-media-view .ast-media-view-body .media-data {
  display: grid;
  grid-template-columns: repeat(3, minmax(8rem, auto));
  font-size: 1.1rem;
  width: 53rem;
}

.ast-media-view .ast-media-view-body .media-data .media-info {
  text-align: center;
  padding: 0.5rem;
  margin: 0.2rem;
}

.ast-media-view .ast-media-view-body .media-data .name {}

.ast-media-view .ast-media-view-body .media-data .cat {}

.ast-media-view .ast-media-view-body .media-data .gps {}

.ast-media-view .ast-media-view-body .media-data .datetime {}



.ast-media-view .ast-media-view-body .controls {
  grid-column: 12 / 13;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 2rem;
  padding: 1rem 0;
  outline: 0.1rem solid;
}

.ast-media-view .ast-media-view-body .controls svg {
  font-size: 2rem;
}

.ast-media-view .ast-media-view-body .controls .control-btn {
  font-size: 0.5rem;
}

.ast-media-view .ast-media-view-body .controls .madia.close-btn {}

.ast-media-view .ast-media-view-body .controls .delete-btn {}

.ast-media-view .ast-media-view-body .controls .stop-btn {}

.ast-media-view .ast-media-view-body .controls .stop-btn {}

.ast-media-view .ast-media-view-footer {
  padding: 2rem;
  outline: 0.1rem solid;
}

.ast-media-view .ast-media-view-footer .progreess-bar {}