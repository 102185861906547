.poip-container {
  width: 80rem;
  height: auto;
  outline: 1px solid;
  padding: 0.5rem;
  font-size: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.poip-container .poip-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  outline: 1px solid;
}

.poip-container .poip-body {
  display: grid;
  grid-template-columns: 45fr 55fr;
  grid-template-areas:
    "inv-payment-image invoices"
    "inv-payment-image payment"
  ;
  gap: 1rem;
  position: relative;
}

.poip-container .poip-body .invoices .header,
.poip-container .poip-body .payment .header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: lightcyan;
}

.poip-container .poip-body .invoices {
  grid-area: invoices;
  outline: 1px solid;
}

.poip-container .poip-body .payment {
  grid-area: payment;
  outline: 1px solid;
}

.poip-container .show {
  visibility: visible;
}

.poip-container .hide {
  visibility: hidden;
}

.poip-container .inv-payment-image {
  grid-area: inv-payment-image;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.1rem solid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
}

.poip-container .poip-body .inv-payment-image .inv-payment-image-header {
  display: flex;
  justify-content: space-between;
}

.poip-container .poip-body .inv-payment-image .inv-payment-image-wrapper {
  text-align: center;
}

.poip-container .poip-body .inv-payment-image .inv-payment-image-wrapper img {
  width: 100%;
  max-height: 38rem;
  object-fit: contain;
}

.poip-container .poip-body .inv-payment-image .inv-payment-image-footer {
  display: flex;
  justify-content: space-evenly;
}