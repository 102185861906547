
/* .user-profile {
  padding: 1rem;
    width: 80vw;
    height: 80vh;
    border: 0.5rem solid;
    overflow: auto;
    border-radius: 1rem;
    background: linen;
} */

.user-profile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  outline: 0.1rem solid;
  padding: 1rem;
  gap: 1rem;
}

.user-profile .user-section {
  outline: 0.1rem solid;
  border-radius: 1rem;

}


/* User details */

.user-data {
  display: grid;
  grid-template-columns: 1fr;
  /* outline: 0.1rem solid blueviolet; */
  padding: 1rem;
  gap: 1rem;
  font-size: 1.6rem;
}

.user-data .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.3rem dashed;
  padding-bottom: 0.5rem;
}

.user-details .body {
  display: grid;
  grid-template-columns: 1.7fr 1fr;
}

.user-data .data-emphasis {
  font-weight: 700;
  color: rgb(42, 94, 248);
}

.user-details .body-section p {
  padding: 0.7rem 0;
}

.user-details .body .user-photo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-details .body .user-photo img {
  border-radius: 50%;
}

.user-details .body .body-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.user-details .body .body-right .photo-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.user-details .body .body-right .photo-btns button {
  width: 4rem;
  height: 3rem;
}

.user-data .user-trns-stats-body,
.user-data .user-notofications-body
 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2rem;
}

.user-data .user-trns-stats-body .body-section {
  /* outline: 0.1rem solid */
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (max-width: 850px) {
  .user-profile {
      grid-template-columns: 1fr;
    }
}