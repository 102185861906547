.tabs-erf-map-info {
  font-size: 1rem;
  /* margin: 1rem; */
  width: 60rem;
  height: 45rem;
}

.tabs-erf-map-info .tabs-show {
  display: block;
}

.tabs-erf-map-info .tabs-hide {
  display: none;
}

.tabs-erf-map-info .tab-active {
  background-color: rgb(229, 254, 254);
  /* border-bottom: 0.1rem solid #e81752; */
}

.tabs-erf-map-info .temi-tabs-header {
  display: block;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  row-gap: 2rem;
  font-size: 1.7rem;
  background-color: khaki;
  height: 3rem;
  padding: 0 1rem;
}

.tabs-erf-map-info .temi-tabs-header .tabs-btns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2rem;
  align-items: center;
}

.tabs-erf-map-info .temi-tabs-header .tabs-btns .th-btn {
  padding: 0.4rem 0;
  margin: 0.2rem 3rem;
}

.tabs-erf-map-info .new-trn {
  font-size: 1.6rem;
}

.tabs-erf-map-info .tabs-body {
  height: calc(65vh - 9rem);
}

/* .tabs-erf-map-info .tabs-body .tab1 {
  display: flex;
  justify-content: center;
} */

.tabs-erf-map-info .tabs-body .tab1 p,
.tabs-erf-map-info .tabs-body .tab2 p {
  margin-top: 3rem;
  text-align: center;
}

.tabs-erf-map-info .tabs-tab {
  text-align: center;
}

.tabs-erf-map-info .tabs-header-table {
  /* margin: 0 1rem 0 0 ; */
}

.tabs-erf-map-info .tabs-tab:hover {
  cursor: pointer;
  border-bottom: 0.1rem solid #e81752;
}

.tabs-erf-map-info .tabs-body-content {
  height: 100%;
}

.tabs-erf-map-info .tabs-body-content .taiem-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs-erf-map-info .tab-name {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  font-weight: 700;
}

.gm-style-iw {
  height: 60rem;
  /* width: 300px; */
}


@media (max-width: 1200px) {}


@media (max-width: 1024px) {}

@media (max-width: 768px) {

  .tabs-erf-map-info {
      width: 27.8rem;
    }


  .tabs-erf-map-info .temi-tabs-header .tabs-btns {
    font-size: 1.3rem;

  }

  .tabs-erf-map-info .temi-tabs-header {
    align-items: center;
    row-gap: 2rem;
    font-size: 1.7rem;
    background-color: khaki;
    height: 3rem;
    padding: 0 1rem;
    width: 27.8rem;
  }

  .tabs-erf-map-info .temi-tabs-header .tabs-btns .th-btn {
    padding: 0.4rem 0;
    margin: 0;
  }
}

@media (max-width: 475px) {}