.table {
  /* height: 100%; */
  height: calc(100% - var(--header-height));
  font-size: 1.5rem;
  min-width: 47rem;
  /* min-width: 80rem; */
  width: 100%;
}

.upload-table {
  height: calc(100% - var(--header-height));
  ;
}

.table .table-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(240, 230, 140);
  height: 3rem;
  font-size: 1.7rem;
  gap: 2rem;
}

.table .table-header .views {
  display: flex;
  justify-content: space-between;
}

.table .table-header .views .views-btn {
  margin: 0 0.5rem;
}

.table .table-header .new-trns-btn-wrapper button,
.table .table-header .close-modal-btn button,
.table .table-header .new-trn-submit-btn {
  border: 1px solid lightgray;
  border-radius: 0.6rem;
  font-size: 1.7rem;
  display: flex;
  padding: 0.5rem;
}

.table .table-header .close-modal-btn {
  display: flex;
  justify-content: end;
}

.table .table-header .close-modal-btn button {
  width: 3rem;
}

.table .table-header .new-trns-btn-wrapper {
  display: flex;
  justify-content: center;
}


.table .table-header .new-trns-btn-wrapper button:hover,
.table .table-header .close-modal-btn:hover {
  cursor: pointer;
  background-color: #e8fee8b5;
  outline: 0.1rem solid rgb(121, 255, 121);
}





.table .trn-ast-checkout .trn-ast-count {
  color: blue;
  font-weight: 800;
}

.table .table-row-btn {
  background-color: #dae4fa99;
  border: 1px solid lightgray;
  /* border-radius: 0.6rem; */
  text-align: center;
  font-size: inherit;
  height: 2.5rem;
  padding: 0 1rem;
  min-width: 4rem;
}

.table .table-row-btn-trn-data {
  width: 11rem;
}

.table .table-row-btn-creator {
  width: 9rem;
}

.table .table-row-btn-ast-no {
  width: 13rem;
}

.table .table-row-btn:hover {
  cursor: pointer
}

.table .data-emphasis {
  font-weight: 700;
  color: rgb(42, 94, 248);
}

.table .table-header-close-btn {
  background-color: #dae4fa99;
  color: rgba(150, 149, 149, 0.539);
  border: 1px solid lightgray;
  border-radius: 0.6rem;
  text-align: center;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.table .table-header-close-btn:hover {
  cursor: pointer;
}

.table .table-header1 {
  display: grid;
  grid-template-columns: 90% 10%;
  padding: 1rem;
  border-radius: 1rem 1rem 0 0;
  border: 1px solid lightgray;
  align-items: center;
}

.tableTrnsForAstsTooltip {
  background-color: rgb(255, 255, 255);
  min-width: 50rem;
}

.tableTrnsForAstsTooltip table th.ast-no {
  min-width: 15rem;
}

.tableTrnsForAstsTooltip th .ast-no {
  width: 15rem;
}

.table-trns-config {
  height: "60vh"
}


@media (max-width: 1200px) {}

@media (max-width: 1024px) {}

@media (max-width: 768px) {
  .table {
    min-width: 100%;
  }

  .ireps-ag-table {
    font-size: 1rem;
  }
}

@media (max-width: 475px) {}