.header-container {
	position: fixed;
	margin: 0 auto;
	padding: 0 0.5rem;
	height: 100%;
	width: 100%;
}

header {
	width: 100%;
	height: var(--header-height);
	position: absolute;
	top: 0;
	left: 0;
	border-bottom: 1px solid;
}

header .logo {
	width: 5rem;
	height: var(--logo-height);
	width: var(--logo-width);
	background-color: rgb(240, 230, 140);
	display: flex;
	align-items: center;
	justify-content: center;
	/* border-radius: 100%; */
	margin: 0.5rem;
}

header .logo svg {
	margin: auto;
	font-size: 2.5rem;
	/* color: #eee; */
}

header .menu-icons {
	font-size: 4rem;
	position: absolute;
	top: 50%;
	right: 2rem;
	transform: translateY(-50%);
	cursor: pointer;
	z-index: 1500;
	display: none;
}



header .menu-icons svg {
	/* this stles the open and close menu icons */
}

.pages {
	height: calc(100vh - var(--header-height));
	width: 100%;
	position: fixed;
	top: var(--header-height);
	left: 0;
	/* outline: 1px solid #cfc5b1; */
	overflow: auto;
	height: 100%;
	padding: 0.3rem 0.4rem 0.8rem;
	min-width: 46rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.loading-spinner,
.test-nav {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.loading-spinner span {
	transform: translateY(-10px);
}



@media screen and (max-width: 850px) {
	header .menu-icons {
		display: block;
	}
}

@media (max-width: 640px) {

	/* .pages {
		width: 64rem;
		display: flex;
	} */

}