:root {
	--modal-header-height: 40px;
}

.modal-container {
	background-color: hsla(0, 0%, 98%, 0.881);
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
}

.modal-showModal {
	display: block;
}

.modal-hideModal {
	display: none;
}

.modal-background {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	/* height: 100%; */
}

.modal-payload {}

.modal-body {}

.modal-footer {}

@media (max-width: 700px) {
	.modal-container {
		position: relative;
		min-height: 100vh;
	}

	.modal-payload {
		width: 70%;
	}

	.modal-body {
		width: 100%;
	}
}