.th {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  border: 0.1rem solid;
  padding: 0.2rem;
  gap: 1rem;
  font-size: 1.7rem;
  background-color: rgb(240, 230, 140);
}

.th .th-left,
.th .th-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.1rem solid;
  padding: 0.5rem;
}

.th .th-left .thl-header-name {}

.th .th-right {}

.th .th-right .th-right-submit-btn {
  /* width: 5rem; */
  font-size: 1.7rem;
}

.th .th-right .th-right-close-btn {
  /* width: 3rem; */
  font-size: 1.7rem;
}

.th button {
  background-color: #dae4fa99;
  color: rgba(150, 149, 149, 0.539);
  border: 1px solid lightgray;
  font-size: 1.5rem;
  height: 3rem;
  padding: 0 1rem;
}

.th button:hover {
  cursor: pointer;
  background-color: #e8fee8b5;
  outline: 0.1rem solid rgb(121, 255, 121);
}

@media (max-width: 700px) {

  .th {
    grid-template-columns: 1fr;
  }
}