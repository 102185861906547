.photo-app {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(249, 252, 244);
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  border: 0.5rem solid #6a5acd;
  border-radius: 1.5%;
}

.show-photo-app {
  display: block;
}

.hide-photo-app {
  display: none;
}

.photo-app .title,
.photo-app .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-radius: 1.5%;
  padding: 0.1rem;
  margin-bottom: 1rem;
}

.photo-app .header .header-subsection {}

.photo-app .header .snap-save {}

.photo-app .header .pic-video {
  text-align: center;
}

.photo-app .header .cameras {
  text-align: center;
}

.photo-app .header .close {
  text-align: right;
}

.photo-app .title .title-subsection button,
.photo-app .header .header-subsection button {
  border-radius: 0.6rem;
  border: 0.1rem solid;
  padding: 0.5rem;
  margin: 0.2rem;
  font-size: 1.2rem;
}

.photo-app .title .title-subsection button:hover,
.photo-app .header .header-subsection button:hover {
  outline: 0.1rem solid rgb(121, 255, 121);
  cursor: pointer;
  /* background-color: #d5ffd5b5; */
}

.photo-app .body {
  grid-template-columns: 50% 50%;
}


.photo-app .body .media-metadata {
  margin: 1.3rem;
  padding: 1rem;
  border: 0.1rem solid;
  font-size: 1.5rem;
}

.photo-app .body .media-metadata .media-metadata-header {
  padding-bottom: 1rem;
  text-align: center;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid;
}

.photo-app .body .media-metadata .media-metadata-body p {
  padding: 1rem;
}

.photo-app .body .container {
  display: flex;
  justify-content: center;
  position: relative;
}

.photo-app .body .container .media-cat {
  position: absolute;
  top: 1rem;
  background-color: white;
  color: black;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-app .footer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 2rem;
}

.photo-app .header .active-camera {
  background-color: chartreuse;
}


@media (max-width: 700px) {
  .photo-app .body .container video {
    width: 400px;
    height: 200px;
  }
}