:root {
	--logo-height: 4rem;
	--logo-width: 5rem;
	--logo-priamry-color: rgb(196, 195, 195);
	--header-height: 5rem;

	/* forms */
	--form-background: rgb(245, 255, 250);
	--form-label-color: rgba(150, 149, 149, 0.539);

	/* form header  */
	--form-header-padding: 1rem;

	---lp-section-header: 20%;
	---lp-section-body: 80%;

	/* asts */
	---asts-primary-color: rgb(78, 105, 255);
	---asts-secondary-color: rgb(154, 169, 255);

	/* trns */
	---trns-primary-color: rgb(113, 255, 78);
	---trns-secondary-color: rgb(171, 253, 151);

	/* erfs */
	---erfs-primary-color: rgb(255, 74, 189);
	---erfs-secondary-color: rgb(255, 161, 221);

	/* typography */
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-family: "Robonto", sans-serif;
	font-size: 62.5%;
}

body {
	overflow-x: hidden;
	width: 100vw;
	height: 100vh;
}

li {
	list-style-type: none;
}

#root,
.app {
	width: 100%;
	height: 100%;
	position: relative;
}