/* 
Purchase Order css file
All prchase order jsx files use this css file for styling  
*/

.poiForm {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.poBtnDeleteItem {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	height: 100%;
}


.myp {
  color: red;
}

.poTable {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  /* border: 3px solid purple; */
}

th,
td {
  padding: 5px;
  text-align: left;
  border: 1px solid ;
}

thead th:nth-child(1) {
  width: 10%;
}

thead th:nth-child(2) {
  width: 35%;
}

thead th:nth-child(3) {
  width: 35%;
}

thead th:nth-child(4) {
  width: 20%;
}

input {
  width: 100%;
}

.from-section-inv-payment-grv {
  
}